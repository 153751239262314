<template>
  <div v-wechat-title="this.goodsinfo.name+ '-药百万'">
    <div class="HeadBox">
      <div>
        <ShopHead :productName="productName"></ShopHead>
      </div>
    </div>
   <div class="wraperBox">
       <div :class="activityType==1?'ProductDetail_left WitBox':'ProductDetail_left'">
          <el-row>
            <div class="wraper" >
              <ActivityModuleGalss class="glass"  v-if="renderFlag"  :goodsinfo="goodsinfo" :ActivityType="activityType"></ActivityModuleGalss>
              <ActivityModuleInfo
                class="info"
                v-if="renderFlag"
                :goodsinfo="goodsinfo"
                :ActivityType="activityType"
              ></ActivityModuleInfo>
            </div>
          </el-row>
          <el-row class="setMealCon">
            <el-col :span="24" v-if="activityType==1">
              <!-- 预售 -->
              <DetailInfo
                class="detailInfo"
                v-if="renderFlag"
                :goodsinfo="goodsinfo"
              ></DetailInfo>
            </el-col>
            <!-- 限时购 -->
            <el-col :span="24"  v-else>
              
              <DetailInfo
                class="detailInfo"
                v-if="renderFlag"
                :goodsinfo="goodsinfo.productArray[0]"
              ></DetailInfo>
              <!-- <p class="setMealCon_header">
                <span>
                  包含商品
                </span>
              </p>
              <div class="setMealCon_main">
                <comboItem :productType="productType" v-for="(item,index) in goodsinfo.productVOList" :key="index" :info="item"></comboItem>
              </div> -->
            </el-col>
          </el-row>
           </div>
        <div class="ProductDetail_right" v-if="activityType!=1">
           <!-- 限时购展示 -->
           <DetailStoreTop  v-if="renderFlag" :postId="goodsinfo.productVOList[0].postId"></DetailStoreTop>
        </div>
    </div>
  </div>
</template>

<script>
// const comboItem=()=>import('components/index/comboItem.vue');
import {   TimeBuyDetail,OpenBookingDetail} from "api/activity.js";
// import Qs from 'qs'
const DetailStoreTop = () => import("components/product/DetailStoreTop.vue");
const ActivityModuleGalss = () => import("components/product/ActivityModuleGalss.vue");
const ActivityModuleInfo = () => import("components/product/ActivityModuleInfo.vue");
const ShopHead = () => import("components/product/ShopHead.vue");
const DetailInfo = () => import("components/product/DetailInfo.vue");
export default {
  name: "ActivityModuleDetail",
   inject:['reload'],
  data() {
    return {
      productType:'setMeal',
      productName:'',
      renderFlag: false,
      goodsinfo: "",
      activityType:''
    };
  },
  components: {
    ActivityModuleGalss,
    ActivityModuleInfo,
    ShopHead,
    DetailInfo,
    // comboItem,
    DetailStoreTop
  },
   watch: {
    //   监听路径变化，获取数据并给商品数据赋值
    "$route.query.productId": function() {
        this.reload();
    },
  },
  created() {
    this.activityType=Number(this.$route.query.ActivityType)
    this.getProductDetail();
  },
  methods: {
    getProductDetail() {
      if(this.activityType==1){
          //  alert('预售' +this.activityType)
           this.productName="预售专区"
          const parmes ={
            promotionId:this.$route.query.promotionId
          };
            // 显示loading
          this.$loading({ fullscreen: true ,background:'#ffffff45'})
          //  console.log(parmes)
            OpenBookingDetail(parmes).then((res) => {
              if(res.data.code==400){
                this.$message.error(res.data.msg);
                let that=this
                setTimeout(function(){that.$router.push({name:"Home"})},500);
              }
              this.productName=res.data.data.name
              this.goodsinfo = res.data.data;
              console.log('预售专区',this.goodsinfo);
              console.log('预售专区',this.productName);
              this.renderFlag = true;
              // 关闭loading
                this.$loading().close();
            });
      }else{
        // alert(this.$route.query.id)
        // alert('限时抢购')
         this.productName="限时抢购"
           const parmes ={
            promotionId:this.$route.query.id
          };
            // 显示loading
          this.$loading({ fullscreen: true ,background:'#ffffff45'})
          TimeBuyDetail(parmes).then(res=>{
            this.goodsinfo = res.data.data;
             this.productName=res.data.data.name
             this.renderFlag = true;

             console.log('88888888889999999999',this.goodsinfo)
             // 关闭loading
                this.$loading().close();
          })
      }
    
    },
  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
.HeadBox{
      padding-top: 16px;
      background:#F6F6F6 ;
      div{
          width: 1200px;
         margin:0px auto;
      }
}
.wraperBox{
   width: 1200px;
   margin:0px auto;
   .ProductDetail_left{
     width:980px;
     float: left;
     margin-top: 20px;
     &.WitBox{
       width: 100%;
     }

   }
   .ProductDetail_right{
     width:220px;
     float: left;
     margin-top: 20px;
     height: auto;
   }
}

.el_main {
  background: #fff;
}
.wraperBox {
  .glass {
    float: left;
  }
  .info {
    float: left;
    margin-left: 20px;
    width: calc(100% - 408px);
  }
  .detailInfo {
    width:970px;
  }
}
.setMealCon{
    width:970px;
}
.setMealCon_header{
   font-weight: bold;
    height: 50px;
    background: #f6f6f6;
    span {
      background: @theme;
      color: #fff;
      font-size: 16px;
      display: inline-block;
      width: 120px;
      text-align: center;
      line-height: 50px;
    }
}
.setMealCon_main{
   box-sizing: content-box;
    padding:0;
    margin-top: 30px;
    margin-bottom: 30px;
    float:left;
    width:100% ;
}
</style>

